const urlRoutes = ({
  home: '/',
  articleDetailsUrl: '/:path/:id',

  site: 'https://gerenciameumandato.com.br',
  startTrial: 'https://app.gerenciameumandato.com.br/onboarding/start',
  login: 'https://app.gerenciameumandato.com.br/auth/login',
});

export default urlRoutes;
