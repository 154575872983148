import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import Details from '../components/details/details';

import { clearDetails, loadData } from '../actions/details';

class Article extends Component {
  constructor(props) {
    super(props);

    const { id } = this.props.match.params;
    const { clearDetails, loadData } = this.props;

    clearDetails();
    loadData({ id });
  }

  render() {
    return (
      <Details />
    )
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    clearDetails,
    loadData,
  }, dispatch)

export default withRouter(connect(null, mapDispatchToProps)(Article));
