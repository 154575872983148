import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Box, TextInput, Text } from 'grommet';

import { debounce } from '../../../../utils';

import { searchArticles } from '../../actions/form-search';

class FormSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      textSearch: null,
    };
  }

  sizeTitle = (size) => {
    const sizes = new Map([
      ['small', 'large'],
      ['medium', 'xlarge'],
    ]);

    return sizes.get(size) || 'xxlarge';
  }

  sizeSubTitle = (size) => {
    const sizes = new Map([
      ['small', 'medium'],
      ['medium', 'medium'],
    ]);

    return sizes.get(size) || 'large';
  }

  search = debounce(text => {
    if(text.length < 2) return;

    const { searchArticles } = this.props;
    searchArticles({ title: text });
  }, 500);

  render() {
    return (
      <ResponsiveContext.Consumer>
        {size => (
          <Box
            direction='column'
            align='center'
            margin={{ top: '8rem' }}
            justify='center'
          >
            <Box
              align='center'
              pad={{
                horizontal: size === 'small' ? 'large' : 'large',
                vertical: 'small'
              }}
              gap='xsmall'
              width='large'
            >
              <Text
                size={this.sizeTitle(size)}
                textAlign='center'
                color='dark-1'
              >
                Dicas e respostas da nossa Equipe
              </Text>

              <Text
                size={this.sizeSubTitle(size)}
                textAlign='center'
                color='dark-3'
              >
                Pesquise em nossos artigos
              </Text>

              <Box
                width='large'
                background='write'
                direction='row'
                border='all'
                pad={{ vertical: 'none', horizontal: 'small' }}
                margin={{
                  top: size === 'small' ? 'large' : 'small',
                  bottom: 'small'
                }}
                round='large'
                flex={{ shrink: 0 }}
              >
                <TextInput
                  plain
                  value={this.state.textSearch ?? ''}
                  placeholder='Digite sua dúvida'
                  autoComplete='disabled'
                  onChange={event => {
                    const text = event.target.value;

                    this.setState({ textSearch: text });
                    this.search(text);
                  }}
                />
              </Box>
            </Box>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    )
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    searchArticles,
  }, dispatch)

export default connect(null, mapDispatchToProps)(FormSearch);
