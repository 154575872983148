
import TYPES from './types';
import { detailsSupport } from '../../../api/support';

const searchDetails = id =>
  detailsSupport(id)
    .then(response => ({
      type: TYPES.detailsData,
      payload: response,
    }))
    .catch(() => {
      return {
        type: TYPES.detailsError
      }
    })

export const loadData = params => {
  if(params && params.id) {
    return [
      searchDetails(params.id),
    ]
  }
};

export const clearDetails = () => ({
  type: TYPES.clearResult,
})
