import axios from 'axios';
import uriApi from '../config/uri-api';

let axiosInstance;

const axiosGlobalInstance = () => {
  if(!axiosInstance) {
    axiosInstance = axios.create({
      baseURL: uriApi,
      timeout: 10000,
    });
  }

  return axiosInstance;
}

export default axiosGlobalInstance();
