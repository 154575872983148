import axiosGlobalInstance from '../http-instance';

const getSupport = params => {
  return axiosGlobalInstance({
    method: 'GET',
    url: 'suporte.php',
    headers: {
      authorized: true,
      action: 'search',
    },
    params: { ...params, page: 1, limit: 10, status: true },
  })
}

export default getSupport;
