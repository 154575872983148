import TYPES from '../actions/types';

const INITIAL_STATE = {
  detail: null,
  getDetailError: false,
}

const detailsReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case TYPES.detailsData:
      return {
        ...state,
        detail: action.payload.data,
        getDetailError: false,
      };

    case TYPES.detailsError:
      return { ...state, getDetailError: true };

    case TYPES.clearResult:
      return { ...state, ...INITIAL_STATE };

    default:
      return state;
  }
}

export default detailsReducer;
