import React from 'react';
import { ResponsiveContext, Box, Anchor, Button } from 'grommet';

import urlRoutes from '../../config/url-routes';

function Header() {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          pad='medium'
          direction={ size === 'small' ? 'column' : 'row' }
          justify='center'
          margin={{
            bottom: size === 'small' ? 'large' : 'medium'
          }}
          background='white'
          elevation='xsmall'
          width='100vw'
          height='80px'
          style={{
            zIndex: 1,
            position: 'fixed',
            top: 0,
          }}
        >
          <Box
            direction='row'
            align='center'
            width={ size === 'small' ? '100vw' : '95vw' }
            pad={{ horizontal: 'medium' }}
            justify='between'
          >
            <Box
              direction='row'
              align='center'
            >
              <Anchor
                size='large'
                label='Gerencia Meu Mandato'
                color='brand'
                href={urlRoutes.site}
              />
            </Box>

            {size !== 'small' && (
              <Box
                direction='row'
                align='center'
                gap='medium'
              >
                <Anchor
                  size='medium'
                  label='Quero experimentar'
                  color='dark-3'
                  href={urlRoutes.startTrial}
                />

                <Button
                  primary
                  label='Acessar'
                  color='brand'
                  onClick={() => window.location = urlRoutes.login }
                />
              </Box>
            )}
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default Header;
