import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

const formattedDateTime = (value, format = 'DD/MM/YYYY') => {
  if(value === undefined || value === null || !value)
    return value;

  return dayjs(value).locale('pt-br').format(format);
}

export default  formattedDateTime
