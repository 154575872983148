import { combineReducers } from 'redux';
import homeReducer from '../modules/home/reducers';
import articleReducer from '../modules/articles/reducers';

const rootReducers = combineReducers({
  homeReducer,
  articleReducer,
});

export default rootReducers;
