import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Analytics } from '../../components';

import { Home } from '../../modules/home/pages';
import { Article } from '../../modules/articles/pages';

import urlRoutes from '../../config/url-routes';

export default function Routes() {
  return (
    <Router>
      <Analytics>
        <Route exact path={urlRoutes.home} component={Home} />
        <Route exact path={urlRoutes.articleDetailsUrl} component={Article} />
      </Analytics>
    </Router>
  )
}
