import axiosGlobalInstance from '../http-instance';

const detailsSupport = id => {
  const formattedParams = {
    id,
  };

  return axiosGlobalInstance({
    method: 'GET',
    url: '/suporte.php',
    params: formattedParams,
    headers: {
      authorized: true,
      action: 'getDetails',
    }
  });
}

export default detailsSupport;
