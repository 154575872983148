import { Component } from 'react';

import FormSearch from '../components/form-search/form-search';
import SearchResult from '../components/search-result/search-result';

class Home extends Component {
  render() {
    return (
      <>
        <FormSearch />
        <SearchResult />
      </>
    )
  }
}

export default Home;
