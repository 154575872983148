import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ResponsiveContext, Box, Text, Anchor } from 'grommet';
import parse from 'html-react-parser';

import { Placeholder } from '../../../../components';
import { formattedDateTime } from '../../../../utils';

import urlRoutes from '../../../../config/url-routes';

class DetailsPost extends Component {
  sizeContainer = (size) => {
    const sizes = new Map([
      ['small', 'xlarge'],
      ['medium', 'large'],
      ['large', 'medium'],
    ]);

    return sizes.get(size) || 'small';
  }

  render() {
    const {
      history,
      detail,
      getDetailError
    } = this.props;

    if(!getDetailError && !detail) {
      return <Placeholder
        title='Carregando...'
        message='Aguarde que estamos preparando as coisas :)'
      />
    }

    if(getDetailError) {
      return <Placeholder
        title='Ops! Algo deu errado :('
        message='Estamos constrangidos por isso.'
        button={{
          label: 'Tentar uma nova pesquisa',
          action: () => history.push(urlRoutes.home),
        }}
      />
    }

    return (
      <ResponsiveContext.Consumer>
        {size => (
          <Box
            direction='column'
            align='center'
            margin={{
              top: size === 'small' ? '6rem' : '8rem'
            }}
          >
            <Box
              width='large'
              pad={{
                horizontal: this.sizeContainer(size),
                vertical: size === 'small' ? 'large' : 'small'
              }}
              gap='xsmall'
            >
              <Anchor
                size='small'
                onClick={ () => history.push(urlRoutes.home) }
                label='Voltar'
              />

              <Text
                size={ size === 'small' ? 'xlarge' : 'xxlarge' }
                color='dark-1'
              >
                {detail.title}
              </Text>

              <Text
                size='small'
                color='dark-3'
              >
                Atualizado em {formattedDateTime(detail.updatedAt, 'DD MMM YYYY')}
              </Text>

              <Box
                margin={{ vertical: 'medium' }}
              >
                {parse(detail.text)}
              </Box>
            </Box>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    )
  }
}

const mapStateToProps = ({ articleReducer: { details } }) => ({
  detail: details.detail,
  getDetailError: details.getDetailError,
});

const mapDispatchToProps = null;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DetailsPost));
