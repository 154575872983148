import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ResponsiveContext, Box, Text } from 'grommet';

import { formattedDateTime } from '../../../../utils';

import urlRoutes from '../../../../config/url-routes';

function SearchResult(props) {
  const {
    loadingFormSearch,
    dataRows,
    getListError,
  } = props;

  const redirect = (path, id) => {
    const url = urlRoutes.articleDetailsUrl.replace(/:path/gi, path).replace(/:id/gi, id);

    const { history } = props;
    history.push(url);
  };

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          direction='column'
          align='center'
        >
          <Box
            align='center'
            pad={{
              horizontal: size === 'small' ? 'large' : 'large' ,
              vertical: 'small'
            }}
            gap='xsmall'
            width='large'
          >
            <Box
              round='xsmall'
              border={{ color: 'light-4' }}
              background='white'
              elevation='small'
              margin={{ bottom: 'large' }}
            >
              {getListError ? (
                <Box
                  width='large'
                  pad={{
                    vertical: size === 'small' ? 'large' : 'medium',
                    horizontal: size === 'small' ? 'large' : 'medium',
                  }}
                  border={{
                    color: 'light-3',
                    side: 'bottom'
                  }}
                >
                  <Text size='medium' textAlign='start'>
                    Ops algo deu errado :(
                  </Text>
                </Box>
              ) : null}

              {loadingFormSearch && !getListError ? (
                <Box
                  width='large'
                  pad={{
                    vertical: size === 'small' ? 'large' : 'medium',
                    horizontal: size === 'small' ? 'large' : 'medium',
                  }}
                  border={{
                    color: 'light-3',
                    side: 'bottom'
                  }}
                >
                  <Text size='medium' textAlign='start'>
                    Carregando...
                  </Text>
                </Box>
              ) : null}

              {!loadingFormSearch && dataRows.length > 0 ? (
                dataRows.map((row, i) => {
                  return (
                    <Box
                      key={i}
                      hoverIndicator={{
                        color: 'light-1'
                      }}
                      width='large'
                      pad={{
                        vertical: size === 'small' ? 'large' : 'medium',
                        horizontal: size === 'small' ? 'large' : 'medium',
                      }}
                      border={{
                        color: 'light-3',
                        side: 'bottom'
                      }}
                      onClick={() => redirect(row.path, row.id) }
                    >
                      <Text
                        size='medium'
                        color='dark-2'
                      >
                        {row.title}
                      </Text>

                      <Text
                        size='small'
                        color='dark-5'
                      >
                        Atualizado em {formattedDateTime(row.updatedAt, 'DD MMM YYYY')}
                      </Text>
                    </Box>
                  )
                })
              ) : null}
            </Box>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

const mapStateToProps = ({ homeReducer: { searchResult } }) => ({
  loadingFormSearch: searchResult.loadingFormSearch,
  dataRows: searchResult.dataRows,
  getListError: searchResult.getListError,
});

export default withRouter(connect(mapStateToProps, null)(SearchResult));
